/* eslint-disable no-undef */
import React, { useReducer, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import 'whatwg-fetch';
import ReCAPTCHA from 'react-google-recaptcha';

import TextField from '@material-ui/core/TextField';

import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import PageTitle from '../components/ui/pageTitle';
import Button from '../components/ui/button';

const ContactFormStyles = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  margin-top: 3rem;
`;

const ContatcFormRow = styled.div`
  display: flex;
  &>*:nth-child(2) {
    margin-left: 1rem;
  }
  &>* {
    flex: 1;
  }
  margin-bottom: 1rem;

  @media(max-width: ${p => p.theme.breakpoints.s}) {
    display: block;
    &>*:nth-child(2) {
      margin-left: 0;
    }
    &>* {
      width: 100%;
      margin-bottom: 1rem !important;
    }
  }
`;

const RecapthaLayout = styled.div`
  &>*>* {
    margin: 0 auto;
  }
  margin: 2rem auto;
`;

const CenteredButton = styled(Button)`
  display: block;
  margin: 2rem auto;
`;

const ButtonTitle = styled.p`
  font-size: 1.2rem;
  font-weight: ${p => p.theme.fonts.weights.bold};
  color: ${p => p.theme.colors.background};
`;

const Message = styled.p`
  font-size: 1.2rem;
  text-align: center;
  margin: 2rem auto;
`;

const ContactForm = ({
  nameTitle, emailTitle, companyNameTitle, phoneTitle, subjectTitle, messageTitle, sendTitle, msgSentTitle, pleaseFillContanctForm,
}) => {
  // eslint-disable-next-line prefer-destructuring
  const GATSBY_CONTACT_FORM_EMAIL_URL = process.env.GATSBY_CONTACT_FORM_EMAIL_URL;
  // eslint-disable-next-line prefer-destructuring
  const GATSBY_RE_CAPTCHA_CLIENT_KEY = process.env.GATSBY_RE_CAPTCHA_CLIENT_KEY;
  if (!GATSBY_CONTACT_FORM_EMAIL_URL) throw new Error('No contact form url env var found!');
  if (!GATSBY_RE_CAPTCHA_CLIENT_KEY) throw new Error('No recaptcha client key env var found!');

  const initialState = {
    name: '',
    companyName: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'name':
        return { ...state, name: action.value };
      case 'companyName':
        return { ...state, companyName: action.value };
      case 'email':
        return { ...state, email: action.value };
      case 'phone':
        return { ...state, phone: action.value };
      case 'subject':
        return { ...state, subject: action.value };
      case 'message':
        return { ...state, message: action.value };
      default:
        throw new Error('Invalid action');
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [isValid, setIsValid] = useState(false);
  const [reCaptchaToken, setRecaptchaToken] = useState(null);
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!state.name || !state.companyName || !state.email || !state.phone || !state.subject || !state.message) {
      setIsValid(false);
      return;
    }
    setIsValid(true);
  }, [state]);

  const onCaptchaChanged = (reCaptcha) => {
    setRecaptchaToken(reCaptcha);
  };

  const onSubmit = async () => {
    if (reCaptchaToken) {
      setIsLoading(true);

      if (isValid && typeof window !== 'undefined') {
        await window.fetch(GATSBY_CONTACT_FORM_EMAIL_URL, {
          method: 'post',
          body: JSON.stringify({ ...state, reCaptchaToken }),
        });
        setIsSent(true);
      }

      setIsLoading(false);
    }
  };

  return (
    <ContactFormStyles>
      <ContatcFormRow>
        <TextField
          placeholder={nameTitle}
          label={nameTitle}
          variant="outlined"
          state={state.name}
          onChange={event => dispatch({ type: 'name', value: event.target.value })}
        />
        <TextField
          placeholder={companyNameTitle}
          label={companyNameTitle}
          variant="outlined"
          state={state.name}
          onChange={event => dispatch({ type: 'companyName', value: event.target.value })}
        />
      </ContatcFormRow>
      <ContatcFormRow>
        <TextField
          placeholder={emailTitle}
          label={emailTitle}
          variant="outlined"
          state={state.name}
          onChange={event => dispatch({ type: 'email', value: event.target.value })}
        />
        <TextField
          placeholder={phoneTitle}
          label={phoneTitle}
          variant="outlined"
          state={state.name}
          onChange={event => dispatch({ type: 'phone', value: event.target.value })}
        />
      </ContatcFormRow>
      <ContatcFormRow>
        <TextField
          placeholder={subjectTitle}
          label={subjectTitle}
          variant="outlined"
          state={state.name}
          onChange={event => dispatch({ type: 'subject', value: event.target.value })}
        />
      </ContatcFormRow>
      <ContatcFormRow>
        <TextField
          placeholder={messageTitle}
          variant="outlined"
          multiline
          rows={8}
          state={state.name}
          onChange={event => dispatch({ type: 'message', value: event.target.value })}
        />
      </ContatcFormRow>
      {!isValid && <p>{pleaseFillContanctForm}</p>}
      <RecapthaLayout>
        <ReCAPTCHA
          sitekey={GATSBY_RE_CAPTCHA_CLIENT_KEY}
          onChange={onCaptchaChanged}
        />
      </RecapthaLayout>
      {reCaptchaToken && !isSent && (
        <CenteredButton onClick={onSubmit} disabled={!isValid || isLoading}>
          <ButtonTitle>
            {sendTitle}
          </ButtonTitle>
        </CenteredButton>
      )}
      {reCaptchaToken && isSent && (
        <Message>
          {msgSentTitle}
        </Message>
      )}
    </ContactFormStyles>
  );
};

export default () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          translations {
            contact
            name
            email
            contactCompanyName
            phone
            subject
            message
            send
            msgSent
            pleaseFillContanctForm
          }
        }
      }
    }
  `);

  const {
    contact: contactTitle,
    name: nameTitle,
    email: emailTitle,
    contactCompanyName: companyNameTitle,
    phone: phoneTitle,
    subject: subjectTitle,
    message: messageTitle,
    send: sendTitle,
    msgSent: msgSentTitle,
    pleaseFillContanctForm,
  } = data.site.siteMetadata.translations;

  return (
    <Layout>
      <SEO title={contactTitle} />
      <PageTitle center>{contactTitle}</PageTitle>
      <ContactForm
        nameTitle={nameTitle}
        emailTitle={emailTitle}
        companyNameTitle={companyNameTitle}
        phoneTitle={phoneTitle}
        subjectTitle={subjectTitle}
        messageTitle={messageTitle}
        sendTitle={sendTitle}
        msgSentTitle={msgSentTitle}
        pleaseFillContanctForm={pleaseFillContanctForm}
      />
    </Layout>
  );
};
